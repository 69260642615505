<template>
  <b-row>
    <b-col cols="12">
      <!-- input search dan select marketplace -->
      <b-row class="p-1">
        <!-- input search -->
        <b-col lg="6">
          <b-form-input
            v-model="search.value"
            type="text"
            size="sm"
            placeholder="Cari produk berdasarkan SKU atau Nama Produk"
          />
        </b-col>

        <!-- select marketplace -->
        <!-- <div class="col-12 col-md-3 mb-1">
          <b-form-select v-model="search.marketplace" size="sm">
            <option :value="null">Pilih Marketplace</option>
            <option
              v-for="(option, index) in marketplaceOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </b-form-select>
        </div> -->

        <!-- select shop -->
        <b-col lg="4">
          <v-select
            v-model="search.shop"
            class="select-size-sm"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="title"
            :options="shopOptions"
            placeholder="Pilih Toko"
          >
            <template #option="{ title, icon }">
              <b-img
                :src="require('@/assets/images/marketplace/' + icon + '.png')"
                :alt="title"
                style="width: 24px; height: 24px"
              />
              <span> {{ title }}</span>
            </template>
          </v-select>
        </b-col>

        <!-- search button -->
        <b-col>
          <b-button
            block
            size="sm"
            variant="primary"
            :disabled="
              search === '' &&
              selectedMarketplace === null &&
              selectedShop === null
            "
            @click="searchProduct"
          >
            <Feather-icon :icon="'SearchIcon'" />
            Cari Produk
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-table-simple striped responsive style="zoom: 85%">
        <b-thead>
          <b-tr>
            <b-th class="bg-transparent">Nama Toko</b-th>
            <b-th class="bg-transparent">Nama Produk</b-th>
            <b-th class="bg-transparent">Stok</b-th>
            <b-th class="bg-transparent">Harga</b-th>
            <b-th class="bg-transparent"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="products.length > 0">
          <b-tr v-for="(item, index) in products" :key="index">
            <b-td>
              <b-img
                :src="
                  require('@/assets/images/marketplace/' +
                    item.marketplace +
                    '.png')
                "
                :alt="item.shopName"
                style="width: 24px; height: 24px"
              />
              {{ item.shopName }}
            </b-td>
            <b-td>
              <div class="item-container d-flex align-items-center">
                <div class="item-thumbnail mr-3">
                  <b-img class="item-image" :alt="item.itemName" :src="image" />
                </div>
                <div class="item-details">
                  <div class="item-name">{{ item.itemName }}</div>
                  <div class="item-id text-muted">
                    {{ item.channelSKU }}
                  </div>
                </div>
              </div>
            </b-td>

            <b-td>{{ stockWithDot(item.stock) }}</b-td>
            <b-td>{{ priceToRupiah(item.price) }}</b-td>
            <b-td class="text-center">
              <b-button size="sm" variant="outline-danger">
                <Feather-icon icon="TrashIcon" /> Hapus Tautan
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else>
          <b-tr>
            <b-td colspan="5" class="text-center">
              Tidak ada produk tertaut pada SKU ini
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BImg,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import vSelect from 'vue-select';

export default defineComponent({
  name: '',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BImg,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    vSelect,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: '',
    },
    shopOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: {
        value: '',
        shop: [],
      },
      products: [],
      marketplaceOptions: [
        { value: 'shopee', text: 'Shopee' },
        { value: 'lazada', text: 'Lazada' },
        { value: 'tokopedia', text: 'Tokopedia' },
      ],
    };
  },
  computed: {
    priceToRupiah() {
      return price => {
        const rupiah = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(price);

        return rupiah;
      };
    },
    stockWithDot() {
      return stock => stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
  async mounted() {
    this.products = this.data;
  },
  methods: {
    searchProduct() {
      const { value, shop } = this.search;
      const filteredData = this.data.filter(item => {
        // by name or SKU
        const isMatched =
          item.itemName.toLowerCase().includes(value.toLowerCase()) ||
          item.channelSKU.toLowerCase().includes(value.toLowerCase());

        const isShopMatched = shop
          ? shop.find(x => x.title === item.shopName)
          : true;

        if (shop.length === 0) {
          return isMatched;
        }

        return isMatched && isShopMatched;
      });

      this.products = filteredData;
    },
  },
});
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.item-container {
  display: flex;
  align-items: center;
}

.item-thumbnail {
  width: 44px;
  height: 44px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.item-id {
  font-size: smaller;
}
</style>
