var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"p-1"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","placeholder":"Cari produk berdasarkan SKU atau Nama Produk"},model:{value:(_vm.search.value),callback:function ($$v) {_vm.$set(_vm.search, "value", $$v)},expression:"search.value"}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('v-select',{staticClass:"select-size-sm",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"title","options":_vm.shopOptions,"placeholder":"Pilih Toko"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return [_c('b-img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require('@/assets/images/marketplace/' + icon + '.png'),"alt":title}}),_c('span',[_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.search.shop),callback:function ($$v) {_vm.$set(_vm.search, "shop", $$v)},expression:"search.shop"}})],1),_c('b-col',[_c('b-button',{attrs:{"block":"","size":"sm","variant":"primary","disabled":_vm.search === '' &&
            _vm.selectedMarketplace === null &&
            _vm.selectedShop === null},on:{"click":_vm.searchProduct}},[_c('Feather-icon',{attrs:{"icon":'SearchIcon'}}),_vm._v(" Cari Produk ")],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table-simple',{staticStyle:{"zoom":"85%"},attrs:{"striped":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"bg-transparent"},[_vm._v("Nama Toko")]),_c('b-th',{staticClass:"bg-transparent"},[_vm._v("Nama Produk")]),_c('b-th',{staticClass:"bg-transparent"},[_vm._v("Stok")]),_c('b-th',{staticClass:"bg-transparent"},[_vm._v("Harga")]),_c('b-th',{staticClass:"bg-transparent"})],1)],1),(_vm.products.length > 0)?_c('b-tbody',_vm._l((_vm.products),function(item,index){return _c('b-tr',{key:index},[_c('b-td',[_c('b-img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require('@/assets/images/marketplace/' +
                  item.marketplace +
                  '.png'),"alt":item.shopName}}),_vm._v(" "+_vm._s(item.shopName)+" ")],1),_c('b-td',[_c('div',{staticClass:"item-container d-flex align-items-center"},[_c('div',{staticClass:"item-thumbnail mr-3"},[_c('b-img',{staticClass:"item-image",attrs:{"alt":item.itemName,"src":_vm.image}})],1),_c('div',{staticClass:"item-details"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.itemName))]),_c('div',{staticClass:"item-id text-muted"},[_vm._v(" "+_vm._s(item.channelSKU)+" ")])])])]),_c('b-td',[_vm._v(_vm._s(_vm.stockWithDot(item.stock)))]),_c('b-td',[_vm._v(_vm._s(_vm.priceToRupiah(item.price)))]),_c('b-td',{staticClass:"text-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"}},[_c('Feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Hapus Tautan ")],1)],1)],1)}),1):_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v(" Tidak ada produk tertaut pada SKU ini ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }