<template>
  <b-modal
    id="modal-bind-sku"
    ref="modalBindSKU"
    :title="`Tautkan SKU [${productData.title} - ${productData.sku}]`"
    size="xl"
    scrollable
    ok-only
    ok-title="Tutup"
  >
    <b-row>
      <b-col>
        <b-media vertical-align="center">
          <template #aside>
            <b-img
              style="
                object-fit: cover;
                object-position: center;
                width: 74px;
                height: 74px;
              "
              :alt="productData.title"
              :src="productData.images && productData.images[0]"
            ></b-img>
          </template>
          <h5 class="mt-0 text-primary">
            <b>{{ productData.title }}</b>
          </h5>
          <small class="mb-0">
            <b>Master SKU :</b> {{ productData.sku }} <br />
            <b>Deskripsi :</b> {{ productData.description }} <br />
          </small>
        </b-media>
        <hr style="border-top: 3px dashed #f0f0f0" />
      </b-col>
      <b-col
        cols="12"
        class="mt-1"
        style="max-height: 290px; overflow-y: auto; zoom: 90%"
      >
        <!-- table boundSKU -->
        <b-tabs fill pills>
          <b-tab
            v-if="productData.boundSKU"
            :title="`Produk Tertaut (${productData.boundSKU.length})`"
          >
            <b-card no-body class="mt-2">
              <TableBoundSKU
                :shop-options="shopOptions"
                :data="productData.boundSKU"
                :image="`https://cdn.statically.io/avatar/${productData.title}`"
              />
            </b-card>
          </b-tab>
          <b-tab title="Produk Tidak Tertaut">
            <UnboundSKU :shop-options="shopOptions" />
          </b-tab>
        </b-tabs>
      </b-col>
      <!-- <b-col v-if="detectInLocalHost()" class="mt-1">
        <b-card title="Debug Mode">
          <pre>{{ productData }}</pre>
        </b-card>
      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BMedia,
  BImg,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BTbody,
  BThead,
  BButton,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import axios from '@axios';
import TableBoundSKU from './TableBoundSKU.vue';
import UnboundSKU from './UnboundSKU.vue';

export default defineComponent({
  name: '',
  props: {
    productData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      shopOptions: [],
    };
  },
  components: {
    BRow,
    BCol,
    BModal,
    BMedia,
    BImg,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BTbody,
    BThead,
    BButton,
    BCard,
    BTabs,
    BTab,
    TableBoundSKU,
    UnboundSKU,
  },
  async mounted() {
    await this.loadShopOptions();
  },
  methods: {
    async detectInLocalHost() {
      // get the url
      const url = window.location.href;

      // check if the url contains localhost
      if (url.includes('localhost')) {
        return true;
      }

      return false;
    },
    async loadShopOptions() {
      // load shop options
      const url = 'https://api.kanaldagang.com/marketplace?page=1&per_page=10';
      const response = await axios.get(url);

      if (response && response.data && response.data.data.length > 0) {
        this.shopOptions = response.data.data.map(shop => ({
          title: shop.shopName,
          icon: shop.marketplaceName,
        }));
      }
    },
  },
});
</script>
