<template>
  <b-card no-body>
    <b-row>
      <b-col cols="12" style="zoom: 90%" class="mt-2">
        <b-tabs v-model="tabIndex" lazy fill>
          <b-tab
            v-for="(tab, index) in Tabs"
            :key="index"
            :disabled="tab.disabled"
          >
            <template #title>
              <b-img
                :src="tab.image"
                :alt="tab.title + ' Image'"
                class="mr-1 marketplace-image"
              />
              {{ tab.title }}
            </template>
            <component :is="TableUnbindComponent" v-bind="{ shopOptions }" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BButton, BImg, BCard, BTabs, BTab } from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TableUnboundSKU',
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    BCard,
    BTabs,
    BTab,
  },
  props: {
    shopOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TableUnbindComponent: () => import('./TableUnbindTokopedia.vue'),
      tabIndex: 0,
      Tabs: [
        {
          title: 'Tokopedia',
          image: require('@/assets/images/marketplace/tokopedia.png'),
        },
        {
          title: 'Shopee',
          image: require('@/assets/images/marketplace/shopee.png'),
        },
        {
          title: 'Lazada',
          image: require('@/assets/images/marketplace/lazada.png'),
        },
      ],
    };
  },

  // if tab index change, then change the component
  watch: {
    async tabIndex() {
      switch (this.tabIndex) {
        default:
          this.TableUnbindComponent = (
            await import('./TableUnbindTokopedia.vue')
          ).default;
          break;
        case 1:
          this.TableUnbindComponent = (
            await import('./TableUnbindShopee.vue')
          ).default;
          break;
        case 2:
          this.TableUnbindComponent = (
            await import('./TableUnbindLazada.vue')
          ).default;
          break;
      }
    },
  },
});
</script>

<style scoped>
.marketplace-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}
</style>
